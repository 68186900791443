import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Suspense, lazy } from "react";
import Spinner from "./Components/Ui/Spinner";

const Four0Four = lazy(() => import("./pages/Four0Four"));
const Layout = lazy(() => import("./pages/Layout"));
const Home = lazy(() => import("./pages/Home"));
const Solution = lazy(() => import("./pages/Solution"));
const OurMission = lazy(() => import("./pages/OurMission"));
const Industries = lazy(() => import("./pages/Industries"));
const Contact = lazy(() => import("./pages/Contact"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense
        fallback={
          <Spinner
            color="#ffffff"
            width="100%"
            height="100vh"
            display="flex"
            justify="center"
            align="center"
          />
        }
      >
        <Layout />
      </Suspense>
    ),
    children: [
      {
        path: "Solutions",
        element: (
          <Suspense
            fallback={
              <Spinner
                color="#ffffff"
                width="100%"
                height="100vh"
                display="flex"
                justify="center"
                align="center"
              />
            }
          >
            <Solution />
          </Suspense>
        ),
      },

      {
        path: "Industries",
        element: (
          <Suspense
            fallback={
              <Spinner
                color="#ffffff"
                width="100%"
                height="100vh"
                display="flex"
                justify="center"
                align="center"
              />
            }
          >
            <Industries />
          </Suspense>
        ),
      },

      {
        path: "OurMission",
        element: (
          <Suspense
            fallback={
              <Spinner
                color="#ffffff"
                width="100%"
                height="100vh"
                display="flex"
                justify="center"
                align="center"
              />
            }
          >
            <OurMission />
          </Suspense>
        ),
      },

      {
        path: "Contact",
        element: (
          <Suspense
            fallback={
              <Spinner
                color="#ffffff"
                width="100%"
                height="100vh"
                display="flex"
                justify="center"
                align="center"
              />
            }
          >
            <Contact />
          </Suspense>
        ),
      },
      {
        path: "",
        element: (
          <Suspense
            fallback={
              <Spinner
                color="#ffffff"
                width="100%"
                height="100vh"
                display="flex"
                justify="center"
                align="center"
              />
            }
          >
            <Home />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: (
          <Suspense
            fallback={
              <Spinner
                color="#ffffff"
                width="100%"
                height="100vh"
                display="flex"
                justify="center"
                align="center"
              />
            }
          >
            <Four0Four />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
