import { extendTheme } from "@chakra-ui/react";

const config = {
  styles: {
    global: {
      "html, body": {
        backgroundColor: "#1B262C",
      },
      ".home": {
        bgGradient: "linear(#1B262C 0%,#0F4C7580 44%,#3282B8 84%,#1B262C 100%)",
      },
      "h1,h2,h3,p": { color: "white" },
      "a:hover": { textDecoration: "none" },
    },
    initialColorMode: "light",
    useSystemColorMode: false,
  },
};
const theme = extendTheme(config);

export default theme;
