import React from "react";
import { GridLoader } from "react-spinners";

const Spinner = ({
  width,
  height,
  display,
  justify,
  align,
  margin,
  padding,
  size,
  color,
  thickness,
  speed,
  enabled,
}) => {
  return (
    <span
      style={{
        width: width || "auto",
        height: height || "auto",
        display: display || "block",
        justifyContent: justify || "center",
        alignItems: align || "center",
        margin: margin || "0",
        padding: padding || "0",
        backgroundColor: "#1b262c",
      }}
    >
      <GridLoader
        size={size || 10}
        color={color}
        thickness={thickness}
        speed={speed}
        enabled={enabled}
      />
    </span>
  );
};

export default Spinner;
